

import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import styled from 'styled-components';
import { Title, Section, Box, Button, Span, Text } from "../../components/Core";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

 
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import { device, breakpoints } from "../../utils";
import Newarrow from '../../assets/image/jpeg/enter.svg';


const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;

const Separator = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #ffffff;

  ::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #f7f7fb;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const SeparatorEnd = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #f7f7fb;

  ::before, ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  ::before {
    top: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }

  ::after {
    bottom: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;



const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;


export default function PRPvampirefaq() {
    return (
      <>
      <Separator />
    
        <Section py="4" id="faq" bg="#f7f7fb" className="pb-md-5 mb pt-md-5">
      <Container className="pb-md-5 mb pt-md-5 pt-3 pb-3">
        <Row className="justify-content-center pb-5">
          <Col lg="12">
   
            <Iwrap>


<Itext as="h2">Questions about <span>Filler Packages</span></Itext>
</Iwrap>
      <SecondText>
      Frequently Asked Questions

      </SecondText>
         
          </Col>
        </Row>


        <Row className="">
       
       


      


        <Col
            lg="12"
            md="6"
            className="mb-4"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
          >
           <Accordion allowZeroExpanded >
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        What does the Filler Package treatment involve?       </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
    After a thorough consultation, numbing cream is applied and all the areas to treat are marked and prepared for the treatment. The treatment will then be carried out using a needle and/or cannula to inject the filler. <br /> <br />

A mirror is given throughout the treatment so you are aware of the changes made and to keep you updated to ensure complete satisfaction. <br /> <br />

You can choose to change the filler package quantity throughout the session. If you request more filler, this can be added upon assessment and suitability. <br /> <br />

Upon completion of the treatment, you will be given the mirror in your hand for the final look. Then, an aftercare email is sent directly to your email for you to be informed on what to expect after the treatment and what to do and don’t. <br /> <br />

    </AccordionItemPanel>
</AccordionItem>




</Accordion>    
          </Col>


          <Col
            lg="12"
            md="6"
            className="mb-4"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
          >
           <Accordion allowZeroExpanded > 

<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        What happens on the day?       </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        We start with a thorough consultation to assess your face and listen to what you want to achieve. We always offer our professional advice, and will discuss together on how much filler will be used before starting your bespoke treatment. 
 

        </p>
    </AccordionItemPanel>
</AccordionItem>



</Accordion>    
          </Col>

          <Col
            lg="12"
            md="6"
            className="mb-4"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
          >
        <Accordion allowZeroExpanded >
        <AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        What side effects are there when choosing Filler Package?       </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        The side effects will vary depending on which area is treated on the day. However, our practitioner will talk you through it all. Alternatively, you can check out our pages for more information on each of our treatments.

        </p>
    </AccordionItemPanel>
</AccordionItem>





</Accordion>    
          </Col>

          <Col
            lg="12"
            md="6"
            className="mb-4"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
          >
        <Accordion allowZeroExpanded >
 
<AccordionItem >
    <AccordionItemHeading>
        <AccordionItemButton>
      
What if I am unhappy with the results?
      </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        It is vital that you explain during the consultation what you desire to ensure the practitioner understands what you are hoping to achieve and ensure optimal results are achieved. <br /> <br />

As dermal fillers provide temporary results, over time the fillers will dissolve and results will fade. Allowing the facial features to go back to its original form.<br /> <br />

Dermal filler treatments are also completely reversible with the use of Hyaluronidase. Using hyaluronidase allows for a complete reversal of the treatment. The areas can be restored to its former shape in a matter of days. If a patient is satisfied with the outcome, the procedure can be safely repeated over time to maintain the results.


        </p>
    </AccordionItemPanel>
</AccordionItem>




</Accordion>    
          </Col>


     
<Col
            lg="12"
            md="6"
            className="mb-4"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
          >
        <Accordion allowZeroExpanded >
 
<AccordionItem >
    <AccordionItemHeading>
        <AccordionItemButton>
      
        Why choose Dermamina London?
      </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        Going to a skilled, specialised provider with extensive knowledge and expertise in non-surgical procedures is fundamental when thinking about non-surgical treatments. A skilled and knowledgeable practitioner will know what to do in order to achieve the intended outcomes while reducing risks, adverse effects, and complications.<br /> <br /> 

Dermamina London is recognised for delivering natural looking results. With safety and honesty as our top priorities, we are dedicated to providing clinically advanced and empirically supported treatments. We will not recommend any procedures if they are not right for you. <br /> <br />

Don't just take our word for it; read the wonderful testimonials that our lovely patients have left, as well as taking a look at the extensive portfolio of non-surgical results. 



        </p>
    </AccordionItemPanel>
</AccordionItem>




</Accordion>    




    <Accordion allowZeroExpanded classname="">
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton>
                    What areas can be treated with the filler packages?
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                <p>
                    Our filler packages are designed to target multiple areas of the face for a comprehensive rejuvenation. The areas that can be treated include the nose, lips, tear trough, cheeks, chin, jaw/pre-jowl, smile lines, temples, and marionette lines. This allows us to address various concerns such as volume loss, contouring, and definition, creating a balanced and harmonious facial profile.
                </p>
            </AccordionItemPanel>
        </AccordionItem>
    </Accordion>
    <br />
    <Accordion allowZeroExpanded classname="">
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton>
                    How long do the results from filler packages last?
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                <p>
                    The results from our filler packages can last between 6 to 18 months, depending on the area treated, the type of filler used, your metabolism, and lifestyle factors. Individual results may vary, and our practitioner will provide detailed information on what you can expect based on your specific treatment plan.
                </p>
            </AccordionItemPanel>
        </AccordionItem>
    </Accordion>
    <br />
    <Accordion allowZeroExpanded classname="">
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton>
                    Is there any downtime associated with filler packages?
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                <p>
                    One of the advantages of our filler packages is that there is minimal to no downtime. You can return to your daily activities and work immediately after the treatment. However, some patients may experience mild swelling, redness, or bruising at the injection sites, which typically subsides within a few days.
                </p>
            </AccordionItemPanel>
        </AccordionItem>
    </Accordion>
    <br />
    <Accordion allowZeroExpanded classname="">
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton>
                    What are the benefits of choosing a filler package over individual treatments?
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                <p>
                    Choosing a filler package offers several benefits, including cost savings and the convenience of addressing multiple areas in a single session. This approach also allows for better overall facial harmony and profile balancing, as treatments can be tailored to enhance the proportions and relationships between different facial features.
                </p>
            </AccordionItemPanel>
        </AccordionItem>
    </Accordion>
    <br />
    <Accordion allowZeroExpanded classname="">
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton>
                    How is the filler quantity determined for the packages?
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                <p>
                    During your consultation, our practitioner will assess your facial structure and discuss your aesthetic goals to determine the appropriate quantity of filler needed. Filler packages start at 3mls, and the quantity can be adjusted throughout the session based on your needs and desired results. Additional filler can be added upon assessment and suitability during the treatment.
                </p>
            </AccordionItemPanel>
        </AccordionItem>
    </Accordion>
    <br />
    <Accordion allowZeroExpanded classname="">
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton>
                    What should I expect during the consultation for filler packages?
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                <p>
                    During the consultation, our practitioner will perform a thorough facial assessment and discuss your primary concerns and expectations. We will provide professional advice on the best approach to achieve your desired results, including the areas to be treated and the amount of filler required. A tailored treatment plan will be devised specifically for you, ensuring that you are fully informed and comfortable with the proposed procedures before proceeding.
                </p>
            </AccordionItemPanel>
        </AccordionItem>
    </Accordion>


          </Col>
      



        </Row>



<br /> 
              <a href 
                                            ="https://connect.pabau.com/bookings.php?compid=8607" 
                                            activeClassName="active"
                                           
                                          
                                        >      <Bookbutton>
                
                
                Book Appointment
                
              
                </Bookbutton> </a>
        


       


        </Container>
        </Section>

        <SeparatorEnd />
      </>


    );
}












