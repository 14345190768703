import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Title, Section, Box, Text, Span } from "../../components/Core";
import { device } from "../../utils";
import imgMobile from "../../assets/image/jpeg/myths.jpg";
import Newarrow from '../../assets/image/jpeg/enter.svg'


const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;

const Separator = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #ffffff;

  ::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #f7f7fb;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const SeparatorEnd = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #f7f7fb;

  ::before, ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  ::before {
    top: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }

  ::after {
    bottom: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;



const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;


const FillerPContentPage = () => (
  <>
    {/* <!-- Content section 2 --> */}
    <Section py={4}>
      <Container className="pt-3 pb-3">

          <Row className="pb-4">
              <Col>
                <Iwrap>


<Itext as="h2">Filler packages - <span>Bespoke package treatments</span> </Itext>
</Iwrap>
      <SecondText>
      What are Filler Packages?

      </SecondText>
              </Col>
          </Row>
        <Row className="align-items-center">
          <Col lg="6" className="mb-4  mb-lg-0">
            <div
              className=""
              data-aos="fade-up"
              data-aos-duration="750"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <img src={imgMobile} alt="What are Filler Packages" className="rounded shadow" />
            </div>
          </Col>
          <Col lg="6" md="9">
            <Box className=" pt-lg-0">
          
              <Text>

              We understand that maintaining your looks can be costly, that’s why we offer personalised filler packages; you can choose to have more than one treatment at a time and at a great price.  <br /><br />
 
 Many choose filler packages to target more than one area of the face, aiming to restore loss of volume, improve quality of the skin and define facial features non surgically.<br /><br />  
  
 By harmonising the features of the face, profile balancing can be achieved, improving the facial profile, particularly the side profile. <br /><br />
  
 Our dermal filler treatments are considered safe as they are done using hyaluronic acid which is a naturally occurring element in our bodies. The treatments last up to 18 months depending on the area treated, metabolism and other lifestyle factors, this varies from patient to patient. <br /><br />
 

              </Text>

              

          <Text>
          In order to balance the features of a facial profile, a single or combination of areas can be treated to achieve profile balancing.<br /><br />  
 
 Profile balancing is accomplished by harmonising the nose, lips, and chin collectively, as the ratios between these three features greatly influence the side profile and overall structure of the face.  <br /><br />
  
 In order to achieve profile balancing, Dermamina London offers filler packages. You pay for filler quantity starting at 3mls, rather than individual treatments. Patients also have the option to purchase a package specifically for profile balancing: Nose + Lips and Chin, Nose + Chin, Nose + Lip packages. <br /><br />
  
 These areas can be completed in one day, requiring less time away from work for the patient, minimal downtime, and immediate results.  <br /><br />
  
 Every treatment includes a consultation, during which our practitioner will take the time to learn about the patient's primary issues and expectations in order develop a treatment plan specifically for them. <br /><br />
 
          </Text>
         

          <Text>
          <b>What areas are included in the filler package? </b> <br />
          </Text>
<Text>
<b>Nose:</b> The nose plays a significant role in achieving facial harmony. Dermal fillers can be used to reshape precise points of the nose, mainly targeting the bridge and tip, achieving a balanced and more defined look. <br /><br /> 

<b>Lips:</b> Dermal filler can be used to achieve your ideal desired lip look, whether you want a subtle natural-looking result or a fuller plumped result. The lip can be enhanced for a more harmonised profile, along with the nose and chin. <br /><br />
 
<b>Tear trough:</b> With this treatment, we can instantly revive and rejuvenate your under eyes, reducing the appearance of dark circles and hollowness.<br /><br />
 
<b>Cheeks:</b> Dermal filler can be used to plump, define and contour the cheek size and shape, enhancing the mid face.<br /><br /> 
 
<b>Chin:</b> The shape of the chin can be defined and restored, providing a better definition to the lower face with dermal filler. Filler can be strategically injected to add structure to the chin and make the chin appear more prominent and in proportion to the rest of the features on the face. <br /><br />
 
<b>Jaw/pre-jowl:</b> The shape of the jawline and pre-jowl area can be defined and enhanced with dermal fillers, giving the lower face more definition, prominence and proportion to the rest of the face's features.<br /><br />
 
<b>Smile lines:</b> Also known as ‘nasolabial folds’, smile lines are vertical lines that extend from the sides of your nose and curves around to the corner of the mouth. Dermal fillers are a great option to replace lost volume in the smile line area, providing instant results and softening the appearance of the lines.<br /><br />

<b>Temple:</b> The loss of volume/fat in your temple can lead to a hollow look in the area. Dermal fillers can help plump and restore volume in the area, reducing the appearance of the hollowness. <br /><br />

<b>Marionette lines:</b> Long vertical downturned lines/creases that can be seen from the corners of the lips to the chin are marionette lines. Dermal filler can help replenish and lift the corners higher up, instantly rejuvenating and giving a younger, fresher and happier look to the face.<br /><br />

</Text>
            
              <a href="https://connect.pabau.com/bookings.php?compid=8607" activeClassName="active"><Bookbutton>Book Appointment
                </Bookbutton> </a>
           
            </Box>
          </Col>
        </Row>

     
      </Container>
    </Section>
  </>
);

export default FillerPContentPage;
